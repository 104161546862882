import axios from 'axios'

const API_PREFIX = "comisiones";

const ENDPOINTS = {
    comision_x_meses: params => axios(`${API_PREFIX}/comision-x-meses`, { params }),
    informe_leecheros: params => axios(`${API_PREFIX}/informe-leecheros`, { params }),
    ver_mas_leecheros: params => axios(`${API_PREFIX}/ver-mas-leecheros`, { params }),
    informe_leechero: params => axios(`${API_PREFIX}/informe-leechero`, { params }),
    lista_leecheros: id_cedis => axios(`${API_PREFIX}/${id_cedis}/lista-leecheros`),
    listar_comisiones: leechero => axios(`${API_PREFIX}/${leechero}/listar-comisiones`),
    crear_comision: params => axios.post(`${API_PREFIX}/comision`, params),
    editar_comision: params => axios.put(`${API_PREFIX}/${params.id}/comision`, params),

    // pagos
    pagarComision: params => axios.post(`${API_PREFIX}/pagar-comision`, params),
    getComisionTeorica: (idLeechero, params) => axios(`${API_PREFIX}/get-comision-teorica/${idLeechero}`, { params }),
    getPagosComision: (idLeechero, params) => axios(`${API_PREFIX}/get-pagos-comision/${idLeechero}`, { params }),
    infoPagoComision: (id, params) => axios(`${API_PREFIX}/get-info-pago-comision/${id}`, { params }),
    anularPagoComision: params => axios.put(`${API_PREFIX}/anular-pago-comision/${params.id}`, params),
};

export default ENDPOINTS
