<template>
    <section>
        <div class="custom-scroll overflow-auto" :style="`height:calc(100vh - ${$route.name == 'admin.cedis.comisiones' ? 180 : 135}px);`">
            <div class="row mx-2 bg-white br-8 my-3">
                <div class="col-12">
                    <div class="row mx-0 my-4">
                        <div class="col-12">
                            <p class="text-gr-general f-18 f-600">
                                Relación de comisiones por mes
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="d-flex mx-0">
                        <div class="col px-0 mt-2 d-middle-center">
                            <cargando v-if="cargandoComision" />
                            <echart :options="relacion_X_mes" />
                        </div>
                        <div class="mx-3" style="width:195px;">
                            <div class="bg-light-f5 text-general p-2 shadow" style="border-radius:12px;">
                                <p class="f-15 my-2 text-center">
                                    Comisión anual teórica
                                </p>
                                <p class="f-600 f-18 my-2 text-center tres-puntos">
                                    {{ separadorNumero(totales.comisiones) }}
                                </p>
                            </div>
                            <div class="bg-light-f5 text-general p-2 mt-3 shadow" style="border-radius:12px;">
                                <p class="f-15 my-2 text-center">
                                    Comisión anual Real
                                </p>
                                <p class="f-600 f-18 my-2 text-center tres-puntos">
                                    {{ separadorNumero(totales.pagos) }}
                                </p>
                            </div>
                            <div class="mt-3 bg-light-f5 text-general p-2 shadow" style="border-radius:12px;">
                                <p class="f-15 my-1 text-center">
                                    Cantidad de pedidos
                                </p>
                                <p class="f-600 f-18 my-1 text-center tres-puntos">
                                    {{ agregarSeparadoresNumero(totales.pedidos) }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 mt-5 mb-3 align-items-center justify-content-start">
                <div class="col-auto text-general d-middle text-general f-17 f-500">
                    Rango de análisis
                </div>
                <div class="col-auto" style="width:174px;">
                    <el-select v-model="rango" placeholder="Seleccionar" size="small">
                        <el-option
                        v-for="item in rangos"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div class="col-auto px-0 mx-2" style="width:248px;">
                    <div class="date-picker-edit">
                        <el-date-picker
                        v-model="fecha"
                        class="w-100"
                        size="small"
                        :disabled="disable_fecha"
                        format="dd-MM-yyyy"
                        value-format="yyyy-MM-dd"
                        type="daterange"
                        unlink-panels
                        popper-class="date-picker-edit-popper"
                        start-placeholder="Fecha inicio"
                        end-placeholder="Fecha fin"
                        />
                    </div>
                </div>
                <div class="col-auto">
                    <div class="btn-general bg-general text-white cr-pointer px-3 f-14 br-8" @click="informe">
                        Mostrar informe
                    </div>
                </div>
                <cargando v-if="cargandoLeecheros" mini />
            </div>
            <div class="row justify-center mx-0 pt-3">
                <div class="col-12 d-middle mb-3 mt-3">
                    <p class="col-auto px-0 text-gr-general f-600  f-18">
                        {{$config.vendedor}}s con  mayor comisión
                    </p>
                    <div class="col-auto ml-auto">
                        <div class="bg-general3 text-white br-20 cr-pointer px-3 py-1" @click="verLeecheros()">
                            Ver más {{$config.vendedor}}s
                        </div>
                    </div>
                </div>
                <div v-for="(leechero,l) in leecheros" :key="l" class="col-auto">
                    <el-tooltip placement="bottom" effect="light">
                        <template slot="content">
                            <div class="row mx-0 my-1">
                                {{ leechero.nombre }}
                            </div>
                            <div class="row mx-0 my-1">
                                {{ leechero.nombre_cedis }}
                            </div>
                        </template>
                        <!-- Card Comision -->
                        <div class="card-comision pt-3 bg-white shadow-edit my-3 mt-5 br-12 text-center position-relative">
                            <!-- Img User -->
                            <img :src="leechero.avatar" width="95" height="95" class="obj-cover rounded-circle border position-absolute z-1" style="left:60px;top:-40px;" />
                            <p class="text-gr-general tres-puntos text-center f-600 f-18 mt-5">
                                {{ separadorNumero(leechero.pagos) }}
                            </p>
                            <p class="text-mute my-2">
                                {{ agregarSeparadoresNumero(leechero.porcentaje,1) }}% del total
                            </p>
                            <!-- <div class="mx-2 my-2 border my-1 d-middle br-12 bg-whitesmoke2 text-muted">
                                <p class="col tres-puntos f-15">
                                    Pedidos
                                </p>
                                <p class="col tres-puntos f-15">
                                    {{ agregarSeparadoresNumero(leechero.pedidos) }}
                                </p>
                            </div> -->
                            <p class="my-2 tres-puntos">
                                <i class="icon-cedis f-17 text-gr-general" /> {{ leechero.nombre_cedis }}
                            </p>
                        </div>
                    </el-tooltip>
                </div>
            </div>
            <informe-leecheros v-if="cedis != 0" />
        </div>
        <!-- Partials -->
        <modal-ver-leecheros ref="VerLeecheros" />
    </section>
</template>

<script>
import Vue from 'vue'
import echarts from 'echarts'
Vue.component('echarts',echarts);
import moment from 'moment'
import { mapGetters } from 'vuex'
import Comisiones from "../../services/comisiones";

let colorGeneral = getComputedStyle(document.documentElement) .getPropertyValue('--color-general')
let colorGeneral2 = getComputedStyle(document.documentElement) .getPropertyValue('--color-db')
let colorGeneral3 = getComputedStyle(document.documentElement) .getPropertyValue('--text-general')

export default {
    components: {
        modalVerLeecheros: () => import('./partials/modalVerLeecheros'),
        informeLeecheros: () => import('./components/informeLeechero')
    },
    middleware:['auth'],
    data(){
        let self = this
        return {
            rangos: [
                { id: 1, nombre: "Hoy"},
                { id: 2, nombre: "Ayer"},
                { id: 3, nombre: "Última semana"},
                { id: 4, nombre: "Semana anterior"},
                { id: 5, nombre: "Último mes"},
                { id: 6, nombre: "Mes anterior"},
                { id: 7, nombre: "Último año"},
                { id: 8, nombre: "Personalizado"},
            ],
            rango: 5,
            fecha: [moment().subtract(1, 'month').toDate(),new Date()],
            disable_fecha: true,
            relacion_X_mes: {
                tooltip: {
                    position: 'top',
                    trigger: 'axis',
                    formatter: function(params){
                        return `Mes: ${params[0].value.fecha}</br>
                        Comisiones teóricas ${self.separadorNumero(params[0].value.comisiones)}</br>
                        Comisiones reales ${self.separadorNumero(params[0].value.pagos)}</br>
                        Pedidos ${self.agregarSeparadoresNumero(params[0].value.pedidos)}`
                    }
                },
                legend: {
                    data:['Comisiones teóricas','Comisiones reales', 'Pedidos']
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                    },
                    {
                        type: 'category',
                        boundaryGap: true,
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    },
                    {
                        type: 'value',
                        scale: true,
                        show: false
                        // name: 'Pedidos',
                    }
                ],
                dataset:{
                    dimensions:['fecha','comisiones','pagos','pedidos'],
                    source:[],
                },
                series: [
                    {
                        name: 'Comisiones teóricas',
                        type: 'line',
                        // data: [10, 5, 15, 25, 35, 40, 45, 50, 55],
                        // xAxisIndex: 1,
                        symbol:'circle',
                        symbolSize: 10,
                        itemStyle: {
                            color: colorGeneral
                        },
                    },
                    {
                        name: 'Comisiones reales',
                        type: 'line',
                        // data: [10, 5, 15, 25, 35, 40, 45, 50, 55],
                        // xAxisIndex: 1,
                        symbol:'circle',
                        symbolSize: 10,
                        itemStyle: {
                            color: colorGeneral3
                        },
                    },
                    {
                        name: 'Pedidos',
                        type: 'bar',
                        yAxisIndex: 1,
                        // data: [10, 5, 15, 25, 35, 40, 45, 50, 55],
                        itemStyle: {
                            color: colorGeneral2
                        },
                    }
                ]
            },
            totales: {
                pedidos: 0,
                comisiones: 0,
                pagos: 0,
            },
            leecheros: [],
            leecheros_original: [],
            total_leecheros: 0,
            cargandoComision: true,
            cargandoLeecheros: true,
        }
    },
    computed: {
        ...mapGetters({
            id_moneda:'cedis/id_moneda',
            id_cedis:'cedis/id_cedis',
            id_cedis_navbar:'cedis/id_cedis_navbar',
            info_cedis:'cedis/info_cedis',
        }),
        cedis(){
            if (this.$route.params.id_cedis){
                return this.$route.params.id_cedis
            } else {
                return this.id_cedis_navbar
            }
        }
    },
    watch: {
        rango(val){
            switch (val){
            case 1:
                this.fecha = []
                this.fecha[0] = new Date()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 2:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'day').toDate()
                this.fecha[1] = moment().subtract(1, 'day').toDate()
                this.disable_fecha = true
                break;
            case 3:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 4:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').startOf('week').toDate()
                this.fecha[1] = moment().subtract(1, 'week').endOf('week').toDate()
                this.disable_fecha = true
                break;
            case 5:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 6:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').startOf('month').toDate()
                this.fecha[1] = moment().subtract(1, 'month').endOf('month').toDate()
                this.disable_fecha = true
                break;
            case 7:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'year').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 8:
                this.fecha = []
                this.fecha[0] = moment().startOf('month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = false
                break;
            default:
                break;
            }
        },
        async id_moneda(val,oldVal){
            if (oldVal == null) return
            await this.cargando()
            await this.montar()

        },
        async cedis(val,oldVal){
            if (oldVal == null) return
            await this.cargando()
            await this.montar()
        },
    },
    async mounted(){
        await this.montar()
    },
    created(){
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    methods: {
        cargando(informe = true){
            if (informe){
                this.cargandoComision = true
            }
            this.cargandoLeecheros = true
        },
        async montar(){
            try {
                if(this.id_cedis === null || this.id_moneda === null){
                    setTimeout(async() => {
                        await this.comision_x_meses()
                        await this.informe_leecheros()
                    }, 1000);
                }else{
                    await this.comision_x_meses()
                    await this.informe_leecheros()
                }
            } catch (error){
                console.log(error)
            }finally{
                this.primeraVez = false
            }
        },
        verLeecheros(){
            let params = {
                fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                id_moneda: this.id_moneda,
                id_cedis: this.cedis
            }
            this.$refs.VerLeecheros.toggle(params);
        },
        async comision_x_meses(){
            try {
                let params = {
                    id_moneda: this.id_moneda,
                    id_cedis: this.cedis
                }
                const {data} = await Comisiones.comision_x_meses(params)
                this.relacion_X_mes.dataset.source = data.datos
                this.totales = data.totales
            } catch (e){
                this.error_catch(e)
            } finally {
                this.cargandoComision = false
            }
        },
        async informe_leecheros(){
            try {
                let params = {
                    fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                    id_moneda: this.id_moneda,
                    id_cedis: this.cedis
                }
                const { data } = await Comisiones.ver_mas_leecheros(params)
                this.$validar(data)

                this.leecheros_original = data.data.leecheros;
                this.leecheros = this.cutArray(window.innerWidth,data.data.leecheros);
                this.total_leecheros = data.data.leecheros.length


            } catch (error){
                this.error_catch(error)
            } finally{
                this.cargandoLeecheros = false
            }
        },
        handleResize(){
            this.leecheros = this.cutArray(window.innerWidth, this.leecheros_original);
        },
        cutArray(width, arr){
            const original = _.cloneDeep(arr)

            let size = arr.length > 9 ? 9 : arr.length;
            if (width >= 1800){ size =  11 }
            else if(width < 1800 && width >= 1624){ size =  10 }
            else if (width <= 1385 && width > 1130){ size =  7 }
            else if(width <= 1130){ size =  5 }
            //console.log(original.slice(0, size));
            return original.slice(0, size);
        },
        async informe(){
            try {
                this.cargando(false)
                await this.informe_leecheros()
            } catch (error){
                console.log(error)
            }
        }
    }
}
</script>

<style lang="scss">
.br-12{ border-radius: 12px ; }
.card-comision{
    width: 204px;
    height: 200px;
}
.shadow-edit{
    box-shadow: 0px 3px 6px #00000029 ;
}
a{
    text-decoration: none !important;
    &:hover{
        color: var(--text-general) !important;
    }
}
</style>
